import React from "react";
import Helmet from "react-helmet";

import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import PurchaseSection from "../LandingPage/Sections/PurchaseSection.jsx";

const dashboardRoutes = [];

class PurchasePage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
      <Helmet>
        <title>BookMerlin</title>
      </Helmet>
        <Header
           color="transparent"
           brand="BookMerlin"
           rightLinks={<HeaderLinks />}
           fixed
           changeColorOnScroll={{
             height: 50,
             color: "#009688"
           }}
           {...rest}
        />
        <Parallax filter image={require("assets/img/background.jpg")}>
          <div className={classes.container}>
            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={6}>
                <div className={classNames(classes.main, classes.mainRaised)}>
                  <div className={classes.container}>
                    <PurchaseSection />
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(PurchasePage);

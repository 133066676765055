import React from "react";

import { Link } from 'gatsby';

import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import simpleFormStyle from "assets/jss/material-kit-react/views/landingPageSections/simpleFormStyle.jsx";

class WorkSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem >
            <h2 className={classes.title}>Purchase Book Merlin</h2>
            <h4 className={classes.description}>
              Please provide your name and email so you can receive your invite.
            </h4>
            <form>
              <GridContainer>
                <GridItem className={classes.form}>
                  <CustomInput
                    labelText="Your Name"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem className={classes.form}>
                  <CustomInput
                    labelText="Your Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridContainer justify="center">
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    className={classes.textCenter}
                  >
                    <Link className={classes.link} to='thanksForPurchase'>
                        Submit
                    </Link>
                  </GridItem>
                </GridContainer>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(simpleFormStyle)(WorkSection);

import { title } from "assets/jss/material-kit-react.jsx";

const simpleFormStyle = {
  section: {
    padding: "20px 15px", //THIS NEEDS TO CHANGE
  },
  title: {
    ...title,
    marginBottom: "50px", 
    marginTop: "5px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  description: {
    color: "#999",
    textAlign: "center"
  }, 
  form: {
    display: "flex",
    flexDirection: "column",
  },
  link: {
    backgroundColor: "#009688",
    color:"white",
    '&:hover': {
      color: "#0000ee"
    },
    fontWeight: "bolder",
    padding:"1em 2em",
    borderRadius:".5em",
  },
  textCenter: {
    textAlign: "center"
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  }
};
 
export default simpleFormStyle;
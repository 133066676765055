import React from "react";

import { Link } from "gatsby";
import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

import { Apps, CloudDownload } from "@material-ui/icons";

import { FaTwitter, FaFacebook, FaInstagram, } from 'react-icons/fa';

import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>

  {/*<ListItem className={classes.listItem}>
      <Link to='/home/#features' className={classes.navLink}>
          Features
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to='/faq' className={classes.navLink}>
          FAQ
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to='/blog' className={classes.navLink}>
          Blog
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to='/login' className={classes.navLink}>
          Login
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <a href="https://app.bookmerlin.com" className={classes.navLink}>
          App Login
        </a>
      </ListItem>
  */}
      <ListItem className={classes.listItemFacebook}>
          <Button
            href="https://www.facebook.com/groups/198225981472595/"
            target="_blank"
            className={classes.navLink}
          >
            <FaFacebook />
            <h6 className={classes.listItemText}>Follow us on FaceBook</h6>
          </Button>
      </ListItem>

      <ListItem className={classes.listItemTwitter}>
          <Button
            href="https://twitter.com/bookmerlinllc"
            target="_blank"
            color="black"
            className={classes.navLink}
          >
            <FaTwitter />
            <h6 className={classes.listItemText}>Follow us on Twitter</h6>
          </Button>
      </ListItem>


   {/*   <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          //title="Follow us on instagram"
          placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="black"
            href="https://www.instagram.com/bookmerlin/"
            target="_blank"
            className={classes.navLink}
          >
            <FaInstagram/>
            Follow us on Instagram
          </Button>
        </Tooltip>
      </ListItem>
    */}

    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
